import http from "./http";
import Service from "../Service";
import { BIconHandThumbsUp } from "bootstrap-vue";

/**
 * @type ClientService
 */
export default class ClientService extends Service {

	list(params) {
		return http.get(`/admin/clients`, {
			headers: this.getHeaders(),
            params
		});
	}
	listNew(params) {
		return http.get(`/admin/clients/new`, {
			headers: this.getHeaders(),
            params
		});
	}

    analytic(params) {
		return http.get(`/admin/clients/analytic`, {
            headers: this.getHeaders(),
            params
        });
	}

    show(id) {
		return http.get(`/admin/clients/${id}`, {
			headers: this.getHeaders()
		});
	}

    remove(id) {
		return http.delete(`/admin/clients/${id}`, {
			headers: this.getHeaders()
		});
	}

    load_profiles(client_id) {
		return http.get(`/admin/clients/${client_id}/profiles`, {
			headers: this.getHeaders()
		});
	}

    load_stores(client_id) {
		return http.get(`/admin/clients/${client_id}/stores`, {
			headers: this.getHeaders()
		});
	}

    load_buyers(client_id, params) {
		return http.get(`/admin/clients/${client_id}/buyers`, {
			headers: this.getHeaders(),
            params
		});
	}

    load_resales() {
		return http.get(`/admin/resales`, {
			headers: this.getHeaders()
		});
	}

    load_sectors() {
		return http.get(`/admin/clients/sectors`, {
			headers: this.getHeaders()
		});
	}

    create(client) {
		return http.post(`/admin/clients`, client, {
			headers: this.getHeaders()
		});
	}

    update(client) {
		return http.put(`/admin/clients/${client.id}`, client, {
			headers: this.getHeaders()
		});
	}

    update_attendance(client, attendance) {
		return http.put(`/admin/clients/${client.id}/attendances/${attendance.id}`, attendance, {
			headers: this.getHeaders()
		});
	}
	getDirectSales(id) {
		return http.get('/admin/direcshopping?cli_id='+id, {
			headers: this.getHeaders()
		})
	}
	UpdateDirectSales(user){
		if (user.usu_id) return http.put('/admin/direcshopping/'+user.usu_id, user, {headers: this.getHeaders()})
		else return http.post('/admin/direcshopping/', user, {headers: this.getHeaders()})
	}
	getBills(id){
		return http.get('clients/conexa/store/'+ id, {
		  headers: this.getHeaders()
		})
	}
	payBill(payload){
		return http.post('external/conexa/quitacao/cobranca ',payload, {
			headers: this.getHeaders()
		})
	}
	deleteProducts(id) {
		return http.post('admin/products/cliente/'+id, {}, {
			headers: this.getHeaders()
		})
	}
	getCotList(id){
		return http.get('admin/gestao/cotacao', {
			headers: this.getHeaders(),
			params:{'cli_id': id}
		  })
	}
	updateCot(payload){
		return http.put('admin/gestao/cotacao/'+payload.id,payload, {
			headers: this.getHeaders(),
		  })
	}
	saveCot(payload){
		return http.post('admin/gestao/cotacao',payload, {
			headers: this.getHeaders(),
		})
	}
	updateCot(payload){
		return http.put('admin/gestao/cotacao/'+payload.id,payload, {
			headers: this.getHeaders(),
		})
	}
	getCot(id){
		return http.get('admin/gestao/cotacao/', {
			headers: this.getHeaders(),
			params:{'cot_id': id}
		})
	}
}
