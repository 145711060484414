<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Revendas
      </div>
    </div>
    <div class="page-title">
      Revendas
    </div>
    <div class="resales-page__actions-container">
      <div class="resales-page__left-content">
        <StandardInput :placeholder="'Digite o nome'"
                        v-if="resaleId < 2"
                       :title="'Nome'"
                       :action="setNameValue"
                       :value="name_filter"
                       class="resales-page-name-input"
                       :type="'text'"/>
        <StandardInput :placeholder="'Selecione o estado'" 
                     :action="setStateValue"
                     :title="'Estado'" 
                     :list="arraySelectState" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
        <!-- <div class="resales-page__actions-wrapper">
          <div class="resales-page__input-title-wrapper">
            <span class="resales-page__input-title">Nome</span>
          </div>
          <div class="resales-page__product-input-container">
            <input @keyup.enter="list(name_filter)" v-model="name_filter" class="resales-page__product-input"  type="text" placeholder="Digite o nome">
            <span @click="list(name_filter)" class="material-icons-outlined search-icon">
              search
            </span>
          </div>
        </div> -->
      </div>
      <div class="resales-page__right-content">
        <Button class="action-buttons" v-if="resaleId < 2" :iconName="'add_circle'" title="Adicionar Revenda" :text="'Adicionar Revenda'" :action="() => { open_resale_modal() }" />
        <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters" />
        <Button class="action-buttons" :iconName="'description'" title="Excel" :text="'Gerar Excel'" :action="downloadExcel" />
      </div>
    </div>
    <div class="page-selected-filters-container">
	    <div v-if="name_filter" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ name_filter }}</div>
        <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="resales.length == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="page-table-container">
      <div v-show="resales.length > 0" >
        <div class="page-table-header">
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text name-width">Nome</div>
          <div class="page-table-header-text cnpj-width">CNPJ</div>
          <div class="page-table-header-text phone-width">Telefone</div>
          <div class="page-table-header-text contact-width">Contato</div>
          <div class="page-table-header-text email-width">Email</div>
          <div class="page-table-header-text store-width">Lojas</div>
          <div class="page-table-header-text actions-width">Ações</div>
        </div>
        <div v-for="(resale, idx) in resales"
             :key="'head-office-' + resale.id"
             :class="getSpecialBackground(idx)">






          <!-- desktop -->
          <div class="desktop page-table-row">
            <div class="page-table-desc-column id-width"><div class="page-table-header-mobile">ID</div>#{{ resale.id }}</div>
            <div class="page-table-desc-column name-width">
              <div class="page-table-header-mobile">Nome</div>
              <div>
                <span class="material-icons-outlined person-icon"
                      :title="resale.contact ? resale.contact.toUpperCase() : ''">
                  person
                </span>
                <span style="text-transform: uppercase;">
                  {{ resale.name }}
                </span>
              </div>
                
            </div>
            <div class="page-table-desc-column cnpj-width"><div class="page-table-header-mobile">CNPJ</div>{{ prefix_cnpj(resale.cnpj) || "" | VMask('## ### ###/####-##') }}</div>
            <div class="page-table-desc-column phone-width">
              <div class="page-table-header-mobile">Telefone</div>
              <cc-whatsapp :phone="resale.phone" />
            </div>
            <div class="page-table-desc-column contact-width" style="text-transform: uppercase;"><div class="page-table-header-mobile">Contato</div>{{ resale.contact }}</div>
            <div class="page-table-desc-column email-width" style="text-transform: uppercase;"><div class="page-table-header-mobile">Email</div>{{ resale.mail }}</div>
            <div class="page-table-desc-column store-width count-button" @click="sendToPage(resale.id)"><div class="page-table-header-mobile">Lojas</div>{{ resale.lojas_count }}</div>
            <div class="page-table-desc-column actions-width">
              <div class="page-table-header-mobile">Ações</div>
              <div>
                <span class="material-icons-outlined manage-acc-icon"
                    title="Gerenciar Usuários"
                    @click="manage_users(resale)">
                  manage_accounts
                </span>
                <span class="material-icons-outlined edit-icon"
                      title="Editar Revenda"
                      @click="edit(resale)">
                  edit
                </span>
                <span class="material-icons-outlined remove-icon"
                      title="Remover Revenda"
                      @click="deleteResale = resale">
                  delete
                </span>
              </div>
              
            </div>
          </div>

          <!-- mobile -->
          <div class="mobile">
            <div class="mobile-table-row">
              <div style="display: flex; align-items: center; gap: 1vw;">
                <span class="material-icons-outlined person-icon"
                      :title="resale.contact ? resale.contact.toUpperCase() : ''">
                  person
                </span>
                <span class="mobile-table-title">
                  {{ resale.name }} ( {{ resale.contact }} )
                </span>
              </div>
              <div>
                #{{ resale.id }}
              </div>
            </div>
            <div class="mobile-table-row">
              <div style="width: 28%;">
                <div class="mobile-table-title">CNPJ</div>
                {{ prefix_cnpj(resale.cnpj) || "" | VMask('## ### ###/####-##') }}
              </div>
              <div class="mobile-colum-center" style="width: 31%;">
                <div class="mobile-table-title">Telefone</div>
                <cc-whatsapp :phone="resale.phone" />
              </div>
              <!-- <div>
                <div class="mobile-table-title">Contato</div>
                {{ resale.contact }}
              </div> -->
              <div class="mobile-colum-end" style="width: 33%;">
                <div class="mobile-table-title">Email</div>
                {{ resale.mail }}
              </div>
            </div>
            <div class="mobile-table-row" style="justify-content: space-evenly;">
              <span class="material-icons-outlined manage-acc-icon"
                    title="Gerenciar Usuários"
                    @click="manage_users(resale)">
                  manage_accounts
                </span>
                <span class="material-icons-outlined edit-icon"
                      title="Editar Revenda"
                      @click="edit(resale)">
                  edit
                </span>
                <span class="material-icons-outlined remove-icon"
                      title="Remover Revenda"
                      @click="deleteResale = resale">
                  delete
                </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer clearfix not-print">
        <cc-paginacao :key="'pag'"
                      classes="orange"
                      :items_by_page_value="items_by_page_value"
                      :total="total_items"
                      :page="paginaAtual"
                      :page_limit="11"
                      v-on:change="list($event)">
        </cc-paginacao>
      </div>
    </div>
    <cc-new-resale-modal :id="current_resale ? current_resale.id : null"
                         @close="is_resale_modal_open=false;current_resale=null"
                         @reload="reload"
                         v-if="is_resale_modal_open" />

    <user-management-modal :resale="current_resale"
                           v-if="is_user_management_modal_open"
                           @close="is_user_management_modal_open=false" />
    <DeleteUserModal 
      v-if="deleteResale" 
      :remove="() => {svc.remove(deleteResale.id).then(() => this.list()); deleteResale = undefined}"
      :close="() => deleteResale = undefined" 
      :subTitle="deleteResale.name"
      title="Tem certeza que quer remover esta revenda?"/>
  </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row py-3">
                            <div class="col col-sm-12 col-lg">
                                <label for="" class="label-control">Buscar por nome</label>
                                <cc-search v-model="name_filter" @keyup="list()" />
                            </div>

                            <div class="col filter-controls">
                                <cc-button
                                    :classes="'fixed danger-outline'"
                                    :icon="'far fa-trash-alt'"
                                    :content="'Limpar Filtros'"
                                    @click="name_filter=null;list()" />
                                <cc-button
                                    :content="'Adicionar Revenda'"
                                    :icon="'fas fa-user-plus'"
                                    :classes="'fixed success'"
                                    id="add-new-resale"
                                    @click="open_resale_modal()" />

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading"  class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>CNPJ</th>
                                    <th>Telefone</th>
                                    <th>Contato</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="resale in resales">
                                    <tr v-bind:key="'head-office-' + resale.id">
                                        <td>#{{ resale.id }}</td>
                                        <td class="text-uppercase text-nowrap">
                                            <i class="fas fa-user-tie seller-icon"
                                                :title="resale.contact ? resale.contact.toUpperCase() : ''"
                                                ></i>
                                            {{ resale.name }}
                                        </td>
                                        <td class="text-nowrap">{{ prefix_cnpj(resale.cnpj) || "" | VMask('## ### ###/####-##') }}</td>
                                        <td><cc-whatsapp :phone="resale.phone" /></td>
                                        <td>{{ resale.contact }}</td>
                                        <td>{{ resale.mail }}</td>
                                        <td>
                                            <div class="actions">
                                                <i class="fas fa-users"
                                                    title="Gerenciar Usuários"
                                                    @click="manage_users(resale)"/>

                                                <i class="fa fa-pencil remove-btn"
                                                    @click="edit(resale)" />

                                                <i title="Remover Revenda"
                                                    class="fa fa-trash"
                                                    @click="remove_resale(resale)"/>
                                            </div>
                                        </td>
                                    </tr>

                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="list($event)"></cc-paginacao>
                </div>
            </div>
        </div>


        <cc-new-resale-modal
            :id="current_resale ? current_resale.id : null"
            @close="is_resale_modal_open=false;current_resale=null"
            @reload="reload"
            v-if="is_resale_modal_open" />

        <user-management-modal
            :resale="current_resale"
            v-if="is_user_management_modal_open"
            @close="is_user_management_modal_open=false" />

    </div> -->
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import ResaleService from "@/services/v3/resales/resale.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import NewResaleModal from "@/modals/resale/new/new-resale.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import UserManagementModal from "@/modals/resale/users/management.modal";
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import DeleteUserModal from "../../modals/resale/users/DeleteUserModal.vue";
import UserService from "@/services/v1/user.service";
import ExcelDownload from '@/services/ExcelDownload.js'

export default {
    mixins: [ loaderMixin ],
    components: {
    ccPaginacao: Paginacao,
    "cc-new-resale-modal": NewResaleModal,
    "user-management-modal": UserManagementModal,
    Button,
    StandardInput,
    DeleteUserModal
},
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            name_filter: "",
            items_by_page_value: 50,
            svc: new ResaleService(),
            user_svc: new UserService(),
            resaleId: localStorage.getItem('revenda'),
            resales: [],
            selectedState: undefined,
            arraySelectState: [],
            current_resale: null,
            is_resale_modal_open: false,
            is_user_management_modal_open: false,
            deleteResale: undefined
        }
    },
    watch: {
      /* name_filter : {
        handler: function() {
          setTimeout(() => {
            this.list(1)      
          }, 600);
        },
        deep: true
      } */
    },
    methods: {
        sendToPage(id){
          const routeData = this.$router.resolve({name: 'clients-attendances', query: {id}});
          window.open(routeData.href, '_blank');
        },
        setNameValue(value) {
          this.name_filter = value
          this.list(this.page)
        },
        clearFilters() {
          this.name_filter = ""
          this.list(this.page)
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        open_resale_modal() {
            this.current_resale = null
            this.is_resale_modal_open = true
        },
        prefix_cnpj(cnpj) {
            let result = `${cnpj}`
            while(result.length < 14) {
                result = "0" + result
            }
            return result
        },
        manage_users(resale) {
            this.current_resale= resale
            this.is_user_management_modal_open = true
        },
        edit(resale) {
            this.current_resale = resale
            this.is_resale_modal_open = true
        },
        setStateValue(value){
          this.selectedState = value
          this.list()
        },
        downloadExcel(){
          setTimeout(() => {
              const payload = {
                  tableName: 'Revendas',
                  userName: localStorage.getItem('user_name'),
                  header: ['ID', 'Nome', 'CNPJ ', 'Telefone', 'Contato', 'Email', 'Lojas '],
                  body: this.resales.map(resale => {
                      return [
                          resale.id,
                          resale.name,
                          this.prefix_cnpj(resale.cnpj),
                          resale.phone,
                          resale.contact,
                          resale.mail,
                          resale.lojas_count
                      ]
                  })
              }
              new ExcelDownload(payload)
          }, 100);
        },
        load_region_data() {
          return this.user_svc.region_data().then(response => response.data).then(data => {
            this.arraySelectState = data.estados.map(element => {
              return {
                    text: element.est_nome,
                    value: element
                  }
            })
          })
        },
        list(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            this.svc.list({ page: new_page, est_id: this.selectedState && this.selectedState.est_id, name: this.name_filter, 'per_page': 2000 })
            .then(response => response.data).then(data => {
                this.resales = []
                data.data.forEach(element => {
                  if (this.resaleId < 2 || element.id == this.resaleId) this.resales.push(element)
                });
                this.total_items = data.meta.total;
                this.is_loading = false;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$resales);
            });
        },
        remove_resale(resale) {
            this.confirm_action({
                message: `Confirma remoção da revenda ${resale.name}?` ,
                callback: () => this.remove(resale.id)
            })
        },
        remove(resale_id) {
            return this.svc.remove(resale_id).then(() => this.list())
        },
        reload() {
            this.paginaAtual = 1
            this.list()
        },
    },
    watch: {
        'state_filter' : function(val) {
            this.state_filter = val ? val : {}
            this.list()
        }
    },
    created() {
        this.list();
        this.load_region_data()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.resales-page__actions-container {
  display: flex;
  justify-content: space-between;
  margin: 3em 0em;
}
.resales-page__left-content {
  display: flex;
  gap: 2vw;
}
.resales-page__right-content {
  display: flex;
}
.resales-page__actions-wrapper {}
.resales-page__input-title-wrapper {
  padding-bottom: 5px;
}
.resales-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.resales-page__product-input-container {
  display: flex;
  align-items: center;
}
.resales-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.resales-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
  cursor: pointer;
}
.page-table-container {
  margin: 3em 0em;
  max-height: none;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.action-buttons {
  margin-left: 20px;
}
.count-button{
  background: rgba(23, 162, 184, 0.05);
  border-radius: 8px;
  cursor: pointer;
  color: #17A2B8;
}
.store-width{
  width: 5vw;
  text-align: center;
  margin-right: 3vw
}
.page-table-header-mobile{
  display: none;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.id-width {
  width: 5%;
}
.name-width {
  flex: 1;
}
.cnpj-width {
  width: 13%;
}
.phone-width {
  width: 14%;
}
.contact-width {
  width: 10%;
}
.email-width {
  width: 20%;
}
.actions-width {
  width: 10%;
}
.person-icon {
  color: #17A2B8;
}
.manage-acc-icon {
  margin-right: 10px;
  color: #17A2B8;
  cursor: pointer;
  font-size: 2.2em;
}
.edit-icon {
  margin-right: 10px;
  color: #606060;
  font-size: 2.2em;
  cursor: pointer;
}
.resales-page-name-input {
  width:  30vw;
  margin-right: 40px;
}
.remove-icon {
  margin-right: 10px;
  color: #CB4243;
  font-size: 2.2em;
  cursor: pointer;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.mobile{display: none;}
@media only screen and (max-width: 850px) {
  .mobile{
    display: block;
  }
  .page-container {
    font-size: 1.5vw;
    margin-right: 3vw;
  }
  .page-table-header{
    display: none;
  }
  .page-table-row{
    flex-direction: column;
  }
  .id-width {
    width: 100%;
  }
  .name-width {
    flex: unset;
    width: 100%;
  }
  .cnpj-width {
    width: 100%;
  }
  .phone-width {
    width: 100%;
  }
  .contact-width {
    width: 100%;
  }
  .email-width {
    width: 100%;
  }
  .actions-width {
    width: 100%;
  }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
  }
  .page-table-header-mobile{
    display: flex;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
  }
  .resales-page__actions-container{
    flex-direction: column;
  }
  .resales-page-name-input {
    width:  100%;
    margin-right: 0;
    margin-bottom: 5vh;
  }
  .resales-page__right-content{
    justify-content: space-between;
  }
  .action-buttons{
    margin-left: 0;
    width: 45%;
  }
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    color: #605F5F;
  }
  .mobile-table-title{
    font-size: 1.1em;
    font-weight: bold;
  }
  .mobile-colum-end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mobile-colum-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .desktop{display: none;}
}
@media only screen and (max-width: 750px) {
  .page-container {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 550px) {
  .page-container {
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 400px) {
  .page-container {
    font-size: 3vw;
  }
}
</style>
