<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Acompanhamento da Implantação
      </div>
    </div>
    <div class="page-title">
      Acompanhamento da Implantação
    </div>
    <div class="attendances-page__actions-container">
      <StandardInput :placeholder="'Grupo ou razão social'" 
                     :title="'Grupo ou Razão Social'" 
                     :action="setGroupValue" 
                     :value="filters.name"
                     class="attendances-page__actions-inputs"
                     :type="'text'"/>
      <div class="page-filters__right-wrapper">
        <div class="page-filters__right-title-wrapper">
          <div class="products-page__datetime-title">Data Inicial</div>
        </div>
        <div class="page-filters__right-select-wrapper">
          <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="fromDate" ref="datepicker" auto type="date"></datetime>
          <span class="material-icons date-icon">
            search
          </span>
        </div>
      </div>

      <div class="page-filters__right-wrapper">
        <div class="page-filters__right-title-wrapper">
          <div class="products-page__datetime-title">Data Final</div>
        </div>
        <div class="page-filters__right-select-wrapper">
          <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="toDate" ref="datepicker" auto type="date"></datetime>
          <span class="material-icons date-icon">
            search
          </span>
        </div>
      </div>
      <Button class="action-buttons excel-button" :iconName="'description'" title="Excel" :text="'Excel'" :action="downloadExcel" style="margin-right: 1vw;"/>
      <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters"/>
    </div>

    <div class="attendances-page__infos-wrapper">
      <div @click="toggleFilter('STARTED')" :style="getActivefilter('STARTED')" class="attendances-page__started-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('STARTED')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Iniciado: {{ startedStatusRequests }} <b v-if="!is_loading && activeStatus('STARTED')">( {{getPercent(startedStatusRequests)}} %)</b>
      </div>
      <div @click="toggleFilter('WAITING_REQUEST')" :style="getActivefilter('WAITING_REQUEST')" class="attendances-page__waiting-first-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('WAITING_REQUEST')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Aguardando Cotação: {{ waiting_requestStatusRequests }} <b v-if="!is_loading && activeStatus('WAITING_REQUEST')">( {{getPercent(waiting_requestStatusRequests)}} %)</b>
      </div>
      <div @click="toggleFilter('ON_WATCH')" :style="getActivefilter('ON_WATCH')" class="attendances-page__on-watch-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('ON_WATCH')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Em acompanhamento: {{ on_watchStatusRequests }} <b v-if="!is_loading && activeStatus('ON_WATCH')">( {{getPercent(on_watchStatusRequests)}} %)</b> 
      </div>
      <div @click="toggleFilter('prefinished')" :style="getActivefilter('prefinished')" style="justify-content: center;"class="attendances-finished-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('prefinished')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Pre-finalizado: {{ prefinished }} <b v-if="!is_loading && activeStatus('prefinished')">( {{getPercent(prefinished)}} %)</b> 
      </div>
      <div @click="toggleFilter('IMPLANTED')" :style="getActivefilter('IMPLANTED')" style="justify-content: end;" class="attendances-page__on-watch-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('IMPLANTED')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Implantado: {{ implanted }} <b v-if="!is_loading && activeStatus('IMPLANTED')">( {{getPercent(implanted)}} %)</b> 
      </div>
    </div>
    <div class="attendances-page__infos-wrapper" style="margin-top: 2vh;">
      <div @click="toggleFilter('ON_ALERT')" :style="getActivefilter('ON_ALERT')" class="attendances-page__on-alert-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('ON_ALERT')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Em alerta: {{ on_alertStatusRequests }} <b v-if="!is_loading && activeStatus('ON_ALERT')">( {{getPercent(on_alertStatusRequests)}} %)</b> 
      </div>
      
      <div @click="toggleFilter('null')" :style="getActivefilter('null')" class="attendances-page__on-alert-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('null')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Sem atendimento: {{ noAttendance }} <b v-if="!is_loading && activeStatus('null')">( {{getPercent(noAttendance)}} %)</b> 
      </div>
      <div @click="toggleFilter('suspended_inactivity')" :style="getActivefilter('suspended_inactivity')" class="attendances-page__suspended-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('suspended_inactivity')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Suspenso por inatividade: {{ suspendedStatusRequests }} <b v-if="!is_loading && activeStatus('suspended_inactivity')">( {{getPercent(suspendedStatusRequests)}} %)</b> </div>
      <div @click="toggleFilter('SUSPENDED')" style="justify-content: center;" :style="getActivefilter('SUSPENDED')" class="attendances-page__suspended-request mobile-alert-size info-container">
        <span v-if="!is_loading && activeStatus('SUSPENDED')" class="material-icons-outlined">check_box</span>
        <span v-else class="material-icons-outlined">check_box_outline_blank</span>
        Suspenso: {{ suspended }} <b v-if="!is_loading && activeStatus('SUSPENDED')">( {{getPercent(suspended)}} %)</b> 
      </div>
      <Button class="filter-button" :iconName="'search'" :action="filterStatus" text="Filtrar" />
    </div>
    
    <div class="page-selected-filters-container">
	    <div v-if="filters.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.name }}</div>
        <span @click="setGroupValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.attendant && filters.attendant.id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.attendant.text }}</div>
        <span @click="setAttendantValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.resale && filters.resale.id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.resale.name }}</div>
        <span @click="setResaleValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="selectedState && selectedState.id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ selectedState.text }}</div>
        <span @click="setStateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="selectedSegment && selectedSegment.ramo_id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ selectedSegment.text }}</div>
        <span @click="setSegmentValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>


    <div class="report-card-container">
      <span class="report-card card-blue" >Total: {{ total_items }}</span>
      <span class="report-card card-green">Atendente: {{ attendenceTotal }}</span>
      <span class="report-card card-yellow">Revenda: {{ resaleTotal }}</span>
      <span class="report-card card-blue">Estado: {{ stateTotal }}</span>
      <span class="report-card card-green">Segmento: {{ segmentTotal }}</span>
    </div>

    <div class="report-card-container report-card-graphic-container">
      <ReportCard v-if="attendenceList.length>0" title="Atendentes" :list="attendenceList" :selectedId="filters.attendant && filters.attendant.id" :setValue="v => setAttendantValue(v)"/>
      <ReportCard v-if="resaleList.length>0" title="Revendas" :list="resaleList" :selectedId="filters.resale && filters.resale.id" :setValue="setResaleCard"/>
    </div>
    <div class="report-card-container report-card-graphic-container" style="margin-bottom: 7vh;">
      <ReportCard v-if="stateList.length>0" title="Estados de atuação" :list="stateList" :selectedId="selectedState && selectedState.id" :setValue="v => setStateValue(v)"/>
      <ReportCard v-if="segmentList.length>0" title="Segmentos de atuação" :list="segmentList" :selectedId="selectedSegment && selectedSegment.ramo_id" :setValue="v => setSegmentValue({ramo_id: v.id, text:v.text})"/>
    </div>
    
    <cc-loader-full v-show="is_loading"  />
    <no-data-available v-if="total_items == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="attendance-page-table-container">
      <div v-show="total_items > 0" class="page-table-header">
        <div class="page-table-header-text corporate-width">Razão / Grupo</div>
        <div class="page-table-header-text area-width">Segmento / CNPJ</div>
        <div class="page-table-header-text resale-width">Revenda / Estado</div>
        <div class="page-table-header-text responsible-width">Responsável / Status</div>
        <div class="page-table-header-text actions-width">Ações</div>
      </div>
      <div v-for="(client, idx) in clients" 
            :key="idx" class="line-padding"
            :class="{
                    canceled: client.attendance.status == 'CANCELED',
                    waiting: client.attendance.status == 'WAITING_CONFIRMATION',
                    confirmed: client.attendance.status == 'CONFIRMED'
                  }">
        <!-- desktop -->
        <div class="page-table-row desktop">
          <div class="page-table-desc-column corporate-width">
            <AttendanceStatusCircle style="margin-left: 10px;" :status="client.attendance.status.id" />
            <div style="width: 2vw;">
              <template v-if="client.filials.length > 0">
                <span v-if="client.isExpanded" class="material-icons-outlined expand-icon" @click="toggleExpanded(client)">expand_less</span>
                <span v-else class="material-icons-outlined expand-icon" @click="toggleExpanded(client)">expand_more</span>
              </template>
            </div>
            <div>
              <strong style="font-weight: 600;">{{ client.head_office ? client.head_office.social_reazon : '' }}</strong>
              <br/>
              {{ client.group_name }}
            </div>
          </div>
          <div class="page-table-desc-column area-width">
            <strong style="font-weight: 600;">{{ client.sectors.length>0 && client.sectors[0].name }}</strong><br>
            {{ client.cnpj || "" | VMask('## ### ###/####-##') }}
          </div>
          <div class="page-table-desc-column resale-width">
            <span style="font-weight: 600;">{{ client.resale ? client.resale.name : '' }}</span> <br> 
            {{ client.head_office ? client.head_office.state.fu : '' }}
          </div>
          <div class="page-table-desc-column responsible-width">
            <div class="change-status">
              <select class="page-table-input-select" v-model="client.attendance.attendant" v-on:change="updateAttendance(client)">
                <option :value="client.attendance.attendant" disabled>{{  client.attendance.attendant.name }}</option>
                <option v-for="manager in arraySelectAttendant" :key="manager.value.id" :value="manager.value">{{manager.text}}</option>
              </select>
            </div>
            <div class="change-status" style="margin-top: 1vh;">
              <select class="page-table-input-select" v-model="client.attendance.status" v-on:change="updateAttendance(client)">
                  <option v-for="status in statuses" :key="status.id" :value="status">{{status.name}}</option>
                </select>
            </div>
          </div>
          <div class="page-table-desc-column actions-width">
            <div style="display: flex;">
              <span @click="showMoreModal=client" class="material-icons-outlined answers-icon">settings</span>
              <router-link style="font-size: inherit;" 
                           :to="{ 
                              name: 'monitors-page', 
                              params: { tab: 'cotacoes' }, 
                              query: { client_id: client.id } 
                            }"
                           :target="'_blank'">
                <span class="material-icons-outlined monitor-icon">
                  desktop_windows
                </span>
              </router-link>
              <span @click="openEditAttachedFilesModal(client)"
                    title="Ver arquivos anexados" 
                    class="material-icons-outlined attach-file-icon">
                attach_file
              </span>
              <span @click="edit(client)"
                    v-if="can('CLIENT', 'UPDATE')"
                    title="Editar" 
                    class="material-icons-outlined edit-user-icon">
                edit
              </span>
              <span class="material-icons-outlined groups-icon answers-icon"
                    title="Gerenciar Usuários"
                    @click="manage_users(client)"
                    v-if="client.head_office && client.head_office.is_head_office && can('CLIENT', 'MANAGE_USERS')">
                groups
              </span>
              <span class="material-icons-outlined" style="cursor: pointer;" @click="phoneClick(client.phone)" @mouseover="show_info(client, true)" @mouseout="show_info(client, false)">info</span>
              <cc-popover :visible="client.show_info || false" :lefty="true">
                  <div>
                      <p><strong class="pop-over-strong">Id:</strong> #{{ client.id }}</p>
                      <p><strong class="pop-over-strong">Data cadastro:</strong> {{ client.created_at | formatDateOnly}}</p>
                      <p><strong class="pop-over-strong">Contato:</strong> <cc-whatsapp style="padding: 0 !important;" :phone="client.phone" /></p>
                  </div>
              </cc-popover>
            </div>
          </div>
        </div>

        <div class="extra-info-container">
          <div v-if="client.ult_cotacao && client.ult_cotacao.length>0" class="extra-info-table-row desktop">
            <span :class="{'old-show': client.old}"><b class="page-table-header-text "> Data final cotação:</b> {{ client.ult_cotacao[0].Dt_Final | formatDateOnly}} </span>
            <span><b class="page-table-header-text ">Ultima cotação:</b>  {{ client.ult_cotacao[0].last_request_id}}</span>
            <span :style="getStatusColor(client.ult_cotacao[0].Status_nome)"><b class="page-table-header-text ">Status:</b>  {{ client.ult_cotacao[0].Status_nome}}</span>
            <span :style="getStatusColor(client.ult_cotacao[0].Status_nome)"><b class="page-table-header-text ">Valor pedidos: </b>  R${{ client.ult_cotacao[0].Total_Compra}}</span>
            <span class="list-btn-size"><b class="page-table-header-text ">Produtos: </b>
              <span class="products-list-btn">
                {{ client.ult_cotacao[0].Total_Produto }} / {{ client.ult_cotacao[0].prod_digitado }} / {{ client.ult_cotacao[0].Prod_Comprados }}
              </span>
            </span>
            <span class="list-btn-size"><b class="page-table-header-text ">Vendedores: </b>
              <div class="competitors-list-btn">
                {{ client.ult_cotacao[0].Total_Concorrente }} / {{ client.ult_cotacao[0].vend_digitado }}
              </div>
            </span>
          </div>
          <div v-if="client.attendance" class="extra-info-table-row desktop" style="margin-top: 1vh;">
            <span><b class="page-table-header-text ">Dt. Cadastro:</b>  {{ client.attendance.data_cadastro | formatDateOnly}}</span>
            <span><b class="page-table-header-text ">Dt. do Contato: </b>{{ client.attendance.data_contato | formatDateOnly}}</span>
            <span><b class="page-table-header-text ">Dt. Prevista Cot.: </b>{{ client.attendance.data_prevista | formatDateOnly}}</span>
            <span><b class="page-table-header-text ">Dt. Primeira Cot.: </b>{{ client.attendance.data_primeira | formatDateOnly}}</span>
            <span><b class="page-table-header-text ">N. Convites: </b>{{ client.attendance.numero_convite }}</span>
            <span><b class="page-table-header-text ">Total Prod.: </b>{{ client.attendance.produtos_cadastrados }}</span>
          </div>
        </div>
        

        <div v-if="client.isExpanded">
          <div class="page-table-header expand-header">
            <div class="page-table-header-text actions-width">CNPJ</div>
            <div class="page-table-header-text corporate-width">Razão</div>
            <div class="page-table-header-text corporate-width">Nome fantasia</div>
            <div class="page-table-header-text actions-width">Estado</div>
            <div class="page-table-header-text area-width">Cidade</div>
            <div class="page-table-header-text area-width">Telefone</div>
          </div>
          <div class="page-table-row desktop expand-row" v-for="filial in client.filials" :key="filial.id">
            <div class="page-table-desc-column actions-width">{{ filial.cnpj }}</div>
            <div class="page-table-desc-column corporate-width">{{ filial.social_reazon }}</div>
            <div class="page-table-desc-column corporate-width">{{ filial.name }}</div>
            <div class="page-table-desc-column actions-width">{{ filial.state.name }}</div>
            <div class="page-table-desc-column area-width">{{ filial.city.name }}</div>
            <div class="page-table-desc-column area-width">{{ filial.phone || '--' }}</div>
          </div>
        </div>


        <!-- mobile -->
        <MobileClientRow :class="getSpecialBackground(idx)" :client="client" :edit="edit" :open_invitations="open_invitations" :openEditAttachedFilesModal="openEditAttachedFilesModal" :action1="client => {current_client = client; show_comments = true; showCommentModal = true}" :statuses="statuses" :arraySelectAttendant="arraySelectAttendant" :updateAttendance="updateAttendance" />

      </div>
      <div class="box-footer clearfix not-print">
        <Paginacao :key="'pag'"
                    classes="orange"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="filters.page"
                    :page_limit="11"
                    v-on:change="filters.page = $event">
        </Paginacao>
      </div>
      <div class="total-requests-container">Total de Requisições: {{ totalRequests }} registros</div>
    </div>
    <MoreActionsModal v-if="showMoreModal" :client="showMoreModal" :closeDialog="()=>showMoreModal=false" :updatePage="list"/>
    <EditAttachedFilesModal v-if="showEditAttachedFilesModal"
                            @close="closeEditAttachedFilesModal"
                            :clientInfo="clientInfo"/>
    <cc-new-client-modal :id="current_client ? current_client.id : null"
                            :closeDialog="closeEditClientModal"
                            v-if="showEditClientModal" />
    <user-management-modal :client="showUserManagementModal"
                           v-if="showUserManagementModal"
                           :closeDialog="()=> showUserManagementModal = false"/>
  </div>
</template>
<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import AttendanceStatus from "@/components/shared/AttendanceStatus";
import CommentList from "@/components/lists/comments/comment-list.component";
import MoreActionsModal from "@/components/lists/MoreActionsModal";
import StorageService from '@/services/TokenService';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service";
import UserService3 from "@/services/v3/management/user.service";
import ClientService from "@/services/v3/client.service";
import ResaleService from "@/services/v3//resales/resale.service";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import EditAttachedFilesModal from '@/modals/edit-attached-files/EditAttachedFiles.modal';
import NewClientModal from "@/modals/client/new/new-client.modal";
import AttendanceStatusCircle from "@/components/shared/AttendanceStatusCircle";
import ExcelDownload from '@/services/ExcelDownload.js'
import MobileClientRow from "./MobileClientRow.vue";
import * as CONSTANTS from '@/constants/constants';
import UserManagementModal from "@/modals/client/users/management.modal";
import ReportCard from "../../components/partials/ReportCard.vue";
import moment from "moment";

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: { /* ccPaginacao:  */
      Paginacao, 
      AttendanceStatus, 
      'cc-new-client-modal' : NewClientModal,
      CommentList, 
      InvitationsModal,
      Button,
      StandardInput,
      EditAttachedFilesModal,
      AttendanceStatusCircle,
      MobileClientRow,
      MoreActionsModal,
      'user-management-modal': UserManagementModal,
      ReportCard
    },
    data(){
      return{
            showCommentModal: false,
            showInvitationsModal: false,
            showEditAttachedFilesModal: false,
            revenda_id: null,
            arraySelectAttendant: [],
            arraySelectResale: [],
            isResaleAdminLoggedIn: undefined,
            showUserManagementModal: undefined,
            // fromDate: moment().add(-5, 'days').toISOString(),
            // toDate: moment().toISOString(),
            fromDate: undefined,
            toDate: undefined,
            arraySelectState: [],
            arraySelectSegment: [],
            selectedSegment: undefined,
            selectedState: undefined,
            showMoreModal: false,
            is_loading: false,
            prefinished: undefined,
            suspended: undefined,
            implanted: undefined,
            total_items: 1,
            name_filter: "",
            current_client: null,
            show_comments: false,
            asdasd: { id: 'prefinished ', name: 'Pre-Finalizado' },




            statuses: [
                { id: 'STARTED', name: 'Iniciado' },
                { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                { id: 'ON_WATCH', name: 'Em acompanhamento' },
                { id: 'IMPLANTED', name: 'Implantado' },
                { id: 'SUSPENDED', name: 'Suspenso' },
                { id: 'ON_ALERT', name: 'Em alerta' },
                { id: 'prefinished ', name: 'Pre-Finalizado' },
                { id: 'suspended_inactivity', name: 'Suspenso por inatividade' },
            ],
            filters: {
                page: 1,
                client: null,
                status: [
                    { id: 'STARTED', name: 'Iniciado' },
                    { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                    { id: 'ON_WATCH', name: 'Em acompanhamento' },
                    { id: 'ON_ALERT', name: 'Em alerta' },
                    { id: 'prefinished', name: 'Pre-Finalizado' },
                ]
            },
            allFilters: {
              page: 1,
              client: null,
              status: [
                { id: 'STARTED', name: 'Iniciado' },
                { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                { id: 'ON_WATCH', name: 'Em acompanhamento' },
                { id: 'IMPLANTED', name: 'Implantado' },
                { id: 'SUSPENDED', name: 'Suspenso' },
                { id: 'ON_ALERT', name: 'Em alerta' },
                { id: 'prefinished ', name: 'Pre-Finalizado' },
                { id: 'suspended_inactivity', name: 'Suspenso por inatividade' },
                { id: undefined, name: 'NULL' },




              ]
            },
            current_client: null,
            show_invitations: false,
            provider_filter: null,
            is_client_modal_open: false,
            showEditClientModal: false,
            filterByProviders: [],
            items_by_page_value: 100,
            user_svc: new UserService(),
            user_svc3: new UserService3(),
            svc: new ClientService(),
            resale_svc: new ResaleService(),
            storage_svc: new StorageService(),
            invitations: [],
            clients: [],
            current_provider: null,
            manage_groups: false,
            resale_id: null,
            breadcrumbs: [
                {
                    name: "Clientes",
                    route: 'clients'
                },
                {
                    name: "Acompanhamento",
                    route: 'clients-attendances'
                }
            ],
            startedStatusRequests: 0,
            on_alertStatusRequests: 0,
            on_watchStatusRequests: 0,
            implantedStatusRequests: 0,
            waiting_requestStatusRequests: 0,
            suspendedStatusRequests: 0,
            noAttendance: 0,
            totalRequests: 0,
            clientInfo: undefined,
            attendenceTotal: 0,
            attendenceList: [],
            resaleTotal: 0,
            resaleList: [],
            stateTotal: 0,
            stateList: [],
            segmentTotal: 0,
            segmentList: []
        }
    },
    // watch: {
    //     filters : {
    //         handler: function() {
    //           setTimeout(() => {
    //             this.list(1)
    //             this.getListNew()
    //           }, 600);
    //         },
    //         deep: true
    //     },
    // },
    computed: {
      getStartedStatus() {
        return this.filters.status.find(s => s.id == 'STARTED')
      },
      getWaitingRequestStatus() {
        return this.filters.status.find(s => s.id == 'WAITING_REQUEST')
      },
      getOnWatchStatus() {
        return this.filters.status.find(s => s.id == 'ON_WATCH')
      },
      getImplantedStatus() {
        return this.filters.status.find(s => s.id == 'IMPLANTED')
      },
      getSuspendedStatus() {
        return this.filters.status.find(s => s.id == 'SUSPENDED')
      },
      getOnAlertStatus() {
        return this.filters.status.find(s => s.id == 'ON_ALERT')
      },
    },
    methods: {
        closeEditClientModal: function() {
          this.showEditClientModal = false;
        },
        edit(client) {
            this.current_client = client
            this.showEditClientModal = true
        },
        filterStatus(){
          this.list(1)
          this.getListNew()
        },
        show_info(item, show) {
            this.$set(item, 'show_info', show)
        },
        manage_users(client) {
            this.showUserManagementModal = client;
        },
        toggleExpanded(client){
          client.isExpanded = !client.isExpanded
          this.$forceUpdate()
        },
        activeStatus(text){
          return this.filters.status.find(s => s.id == text)
        },
        getActivefilter(text){
          if (this.activeStatus(text)) return 'border-width: 1px;'
        },
        toggleFilter(text){
          if (this.filters.status.find(s => s.id == text)) this.filters.status = this.filters.status.filter(s => s.id != text)
          else this.filters.status.push({id: text})
          this.$forceUpdate()
        },
        getPercent(value){
          if (!value || this.total_items < 1) return 0
          return (value / this.total_items * 100).toFixed(2)
        },
        downloadExcel(){
          setTimeout(() => {
              const payload = {
                  tableName: 'Acompanhamentos',
                  userName: localStorage.getItem('user_name'),
                  header: ['ID', 'DT Cadastro', 'CNPJ ', 'Telefone', 'Razão', 'Grupo', 'Revenda ', 'Estado', 'Responsável', 'Status'],
                  body: this.clients.map(value => {
                      return [
                          value.id,
                          moment(value.created_at).format("DD/MM/YYYY HH:mm"),
                          value.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1 $2 $3/$4-$5"),
                          value.phone ? value.phone : '--' ,
                          value.head_office ? value.head_office.social_reazon : '--',
                          value.group_name,
                          value.resale ? value.resale.name : '--',
                          value.head_office ? value.head_office.state.fu : '--',
                          value.attendance.attendant.name,
                          value.attendance.status.name
                      ]
                  })
              }
              new ExcelDownload(payload)
          }, 100);
        },
        getStatusColor (status) {
          if (status == 'PEDIDO GERADO' ) return 'color: #7683F6; font-weight: 700;'
            else if (status == 'CONGELADA'|| status == 'ABERTA') return 'color: gray; font-weight: 700;'
            else if (status == 'GERADA') return 'color: #30AA4C; font-weight: 700;'
            else if (status == 'NAO DIGITADA') return 'color: #CD2A2A; font-weight: 700;'
            else if (status == 'CANCELADA') return 'color: #CD2A2A; font-weight: 700;'
        },
        openEditAttachedFilesModal(client) {
          this.clientInfo = client
          this.showEditAttachedFilesModal = true;
        },
        updateAttendance(client){
          this.update_attendance(client, {
                                    ...client.attendance,
                                    status: { id: client.attendance.status.id }
                                }, client.attendance) 
        },
        getResaleId() {
          this.revenda_id = this.storage_svc.get("revenda");
          if (this.revenda_id == 1) {
            this.isResaleAdminLoggedIn = true
          } else {
            this.isResaleAdminLoggedIn = false
            this.filters.resale = {id: this.revenda_id}
          }
        },
        closeInvitationsModal: function() {
          this.showInvitationsModal = false;
        },
        closeCommentModal: function() {
          this.showCommentModal = false;
        },
        closeEditAttachedFilesModal: function() {
          this.showEditAttachedFilesModal = false;
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        clearFilters() {
          this.filters.name = ''
          this.filters.attendant = ''
          if (this.isResaleAdminLoggedIn) this.filters.resale = ''
          this.filters.status = []
          this.selectedState = undefined
          this.filters.resale = undefined
          this.selectedSegment = undefined
          this.getListNew()
        },
        setGroupValue(value){
		      this.filters.name = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
		    },
        setAttendantValue(value){
		      this.filters.attendant = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
          this.getListNew()
		    },
        setResaleCard(value){
          this.setResaleValue({id: value.id, name: value.text})
        },
        setResaleValue(value){
		      this.filters.resale = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
          this.getListNew()
		    },
        setStatusValue(value){
		      this.filters.status = value
		    },
        open_invitations(client) {
          this.current_client = client
          this.showInvitationsModal = true
        },
        edit_status(attendance) {
            attendance.is_editing = !attendance.is_editing
            this.$forceUpdate()
        },
        load_resales() {
			      return this.resale_svc.list().then(response => {
                this.arraySelectResale.push({text: 'todos', value: undefined})
                response.data.data.forEach(element => {
                  if (this.filters.resale && this.filters.resale.id == element.id) this.filters.resale = element
                    this.arraySelectResale.push({
                        text: element.name,
                        value: element
                    })
                })
            })
		},
        update_attendance(client, attendance, obj_ref) {
            this.svc.update_attendance(client, {
                id: attendance.id, status: attendance.status.id,
                attendant_id: attendance.attendant.id
            }).then(response => response.data).then((data)=>{
                attendance.saved = true
                obj_ref.is_editing = false
                const notification = {
                    type: 'success',
                    message: 'Dados salvos com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                this.$forceUpdate()
            })
        },
        renotify() {
            this.confirm_action({
                subtitle: `#${this.filters.client.name.toUpperCase()}`,
				message: "Confirma reenvio de notificações dos convites pendentes?",
				callback: () => {
                    this.present_loader("Reenviando notificações...")
                    this.svc.notify_all(this.filters.client).then(() => {
                        this.present_info("Notificações Enviadas!")
                    })
				}
			})
        },
      load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.arraySelectState = data.estados.map(element => {
          return {
                text: element.est_nome,
                value: element
              }
        })
        this.arraySelectState.unshift({text: 'todos',value: undefined})
			})
		},
    getColor(i){
      const colorList = [ "#FF0000",  "#00FF00", "#0000FF",  "#FFFF00",    "#FF00FF","#00FFFF", "#800000", "#008000","#000080", 
                "#FFC0CB", "#800080",  "#FFD700","#008080", "#A52A2A","#FFFFF0","#F0E68C","#F08080", "#FAEBD7","#D2691E", "#FF7F50",  
                "#FFFACD", "#7FFF00", "#BA55D3", "#DAA520", "#C71585", "#FF8C00", "#FF69B4" ]
      while(i>26) i = i-27
      return colorList[i]
    },
    getListNew(){
      let params = {
        status: this.filters.status ? this.filters.status.map(s => s.id) : [],
        cli_id_revenda: this.filters.resale ? this.filters.resale.id: null, 
        ramo_id: this.selectedSegment && this.selectedSegment.ramo_id,
        est_id: this.selectedState && this.selectedState.id,
        attendant_id: this.filters.attendant && this.filters.attendant.id
      }
      this.svc.listNew(params).then(resp => {
        this.attendenceTotal = resp.data.summary.clients
        this.attendenceList = resp.data.datail.clients.map((v,i) =>{return {id: v.attendant_id, text: v.usu_nome, color: this.getColor(i), value:v.total_count}}).sort((a,b)=> b.value-a.value)
        this.resaleTotal = resp.data.summary.total_revenda
        this.resaleList = resp.data.datail.total_revenda.map((v,i) =>{return {id: v.cli_id_revenda, text: v.nome, color: this.getColor(i), value:v.total_count}}).sort((a,b)=> b.value-a.value)
        this.stateTotal = resp.data.summary.total_estado
        this.stateList = resp.data.datail.total_estado.map((v,i) =>{return {id: v.est_id, est_sigla: v.est_sigla, text: v.est_nome, color: this.getColor(i), value:v.total_count}}).sort((a,b)=> b.value-a.value)
        this.segmentTotal = resp.data.summary.total_ramo
        this.segmentList = resp.data.datail.ramo.map((v,i) =>{return {id: v.ramo_id, text: v.ramo_descricao, color: this.getColor(i), value:v.total_count}}).sort((a,b)=> b.value-a.value)
      })
    },
    getListTotals() {
      let params = {
        page: this.filters.page,
        name: this.filters.name,
        attendant_id: this.filters.attendant ? this.filters.attendant.id : null,
        resale: this.filters.resale ? this.filters.resale.id: null, 
        sort: 'tbl_cliente.cli_id',
        count: 1,
        state: this.selectedState && this.selectedState.est_sigla,
        ramo: this.selectedSegment && this.selectedSegment.ramo_id
      }
        this.svc.list(params)
        .then(response => response.data).then(data => {
          this.on_alertStatusRequests = 0
          this.on_watchStatusRequests = 0
          this.startedStatusRequests = 0
          this.waiting_requestStatusRequests = 0
          this.suspendedStatusRequests = 0
          this.implantedStatusRequests = 0
          this.prefinished = 0
          this.suspended = 0
          this.implanted = 0
          this.noAttendance = 0
          data.data.forEach((item) => {
            if(item.status == 'ON_ALERT') this.on_alertStatusRequests = item.total
            if(item.status == 'ON_WATCH') this.on_watchStatusRequests = item.total
            if(item.status == 'STARTED') this.startedStatusRequests = item.total
            if (item.status == 'WAITING_REQUEST') this.waiting_requestStatusRequests = item.total
            if (item.status == 'suspended_inactivity') this.suspendedStatusRequests = item.total
            if (item.status == 'IMPLANTED') this.implantedStatusRequests = item.total
            if (item.status == 'prefinished') this.prefinished = item.total
            if (item.status == 'SUSPENDED') this.suspended = item.total
            if (item.status == 'IMPLANTED') this.implanted = item.total
            if (!item.status) this.noAttendance = item.total
          })
        }).catch(error => {
            this.is_loading = false;
            ErrorHandlerService.handle(error, this.$store);
        });
      },
      setStateValue(value){
        this.selectedState = value
        this.list()
        this.getListTotals(this.page)
        this.getListNew()
      },
      setSegmentValue(value){
        this.selectedSegment = value
        this.list()
        this.getListTotals(this.page)
        this.getListNew()
      },
      getClientsSegmentList() {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
           }
          axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/ramo_clientes`, config)
          .then(data => {
            this.arraySelectSegment.push({text: 'todos',value: undefined})
            data.data.forEach(element => {
              this.arraySelectSegment.push({
                text: element.ramo_descricao,
                value: element
              })
            })
          }).catch(error => {
            console.log(error)
          })
        },
        phoneClick(phone){
          let begin = 3
          if(!phone)return null
          if(phone.length == 10)begin = 2
          window.open(`https://api.whatsapp.com/send?phone=${phone.substr(0, 2)}${phone.substr(begin, phone.length)}`, '_blank').focus();
        },
        load_managers() {
          return this.user_svc3.list().then(response => {
                    this.managers = response.data
                    response.data.forEach(element => {
                      if (this.isResaleAdminLoggedIn || this.revenda_id == element.id_revenda){
                        this.arraySelectAttendant.push({
                            text: element.name,
                            value: element
                        })
                      }
                    })
                })
        },
        list() {
            this.is_loading = true;
            let params = {
                page: this.filters.page,
                status: this.filters.status ? this.filters.status.map(s => s.id) : [],
                name: this.filters.name,
                attendant_id: this.filters.attendant ? this.filters.attendant.id : null,
                resale: this.filters.resale ? this.filters.resale.id: null, 
                sort: 'tbl_cliente.cli_id',
                state: this.selectedState && this.selectedState.est_sigla,
                ramo: this.selectedSegment && this.selectedSegment.ramo_id,
                name: this.filters.name,
                toDate: this.toDate?.split('T')[0],
                fromDate: this.fromDate?.split('T')[0]
            }
            this.clients = []
            this.svc.list(params)
            .then(response => response.data).then(data => {
                const now = moment()
                data.data.forEach(element => {
                  if(element.attendance && element.attendance.status.id == "WAITING_REQUEST") element.attendance.status.name = "Aguardando cotação"
                  if(element.attendance && element.attendance.status.id == "prefinished") element.attendance.status = { id: 'prefinished ', name: 'Pre-Finalizado' }
                  if(element.attendance && element.attendance.status.id == "suspended_inactivity") element.attendance.status.name = "Suspenso por inatividade"
                  if (!element.attendance) element.attendance = { status: {id: undefined, name: undefined}, attendant:{name:undefined}}
                  if (element.ult_cotacao.length>0){
                    const cotDate = moment(element.ult_cotacao[0].Dt_Final)
                    if(now-cotDate > 1728000000) element.old = true
                  }
                  this.clients.push(element)
                });
                this.totalRequests = data.meta.total
                this.total_items = data.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    async created() {
        this.getResaleId()
        this.load_resales();
        this.load_region_data()
        this.getClientsSegmentList()
        this.load_managers()
        if (this.$route.query.id) {
          this.filters.resale = {id: this.$route.query.id}
          this.filters.status = this.statuses
        } else this.list();
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
        this.getListTotals()
        this.getListNew()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';
    
    .manage-status {
        display: flex;
        align-items: center;
        i.fa-edit {
            font-size: 1rem;
            color: $secondary-color;
            cursor: pointer;
            margin-right: 10px;
        }
    }
    .change-status {
        display: flex;
        align-items: center;
        width: 100%;
        .success-update {
            margin-left: 5px;
            visibility: hidden;
            color: $brand-success;
            &.visibile {
                visibility: 'visible';
            }
        }
    }

    .canceled {
        i {
            color: $brand-danger;
        }
    }
    .waiting {
        i {
            color: $yellow2;
        }
    }
    .confirmed {
        i {
            color: $ternary-color;
        }
    }

    .zap {
        i {
            color:#4AC959;
        }
        width: fit-content;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }
    .radio-group {
        display: flex;
        align-items: center;
        .form-check {
            margin-right: 20px;
            margin-right: 20px;
            align-items: center;
            display: flex;
            height: 2rem;
            .form-check-label {
                cursor: pointer;
                margin-bottom: 0;
                display: flex;
                height: 100%;
                align-items: center;
                margin-left: 9px;
                font-size: 16px;
                color: black;
            }
            input {
                cursor: pointer;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 5px;
                margin-top: 0px;
                margin-bottom: 3px;
            }
        }
    }
    .filters {
        padding: 1rem;
        border-radius: 5px;
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-table-input-select{
  padding: 0.25vh 1vw;
  border-radius: 10px;
  color: #35495e;
  border: 1px solid #E5E5E5;
  &:focus{outline: none;}
  width: 90%;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}
.products-page__datetime-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.datetime-input {
  border-radius: 8px !important;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.attendances-page__clear-filter-container {
  display: flex;
  justify-content: flex-end;
}
.attendances-page__actions-container {
  display: flex;
  align-items: end;
  margin: 2em 0em;
  gap: 1vw;
}
.excel-button{
  margin-left: auto;
}
.attendances-page__product-input-container {
  display: flex;
  align-items: center;
}
.attendances-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.attendances-page__actions-inputs{
  width: 30%;
}
.attendances-page__actions-wrapper{
  width: 40%;
}
.attendances-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
}
.select-input-width {

  margin-right: 10px;
}
.attendances-page__input-title-wrapper {
  padding-bottom: 5px;
}
.attendances-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 20px;
}
.attendance-page-table-container {
  margin: 2em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.report-card-container{
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vh;
}
.report-card{
  text-align: center;
  display: block;
  width: 15vw;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 1vh 0;
  font-size: 1.5em;
  font-weight: bold;
}
.card-blue{
  background-color: rgb(208, 226, 232);
  border-color: rgb(130, 130, 249);
}
.card-green{
  background-color: rgb(200, 234, 200);
  border-color: rgb(36, 141, 36);
}
.card-yellow{
  background-color: rgb(247, 247, 234);
  border-color: rgb(249, 249, 87);
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.info-container{
  display: flex;
  align-items: center;
  width: 17%;
  gap: 2%;
}
.filter-button{
  width: 17%;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.line-padding{
  padding: 1vh 0;
  border-bottom: 1px solid lightgray;
}
.products-list-btn {
  background-color: #17A2B80D;
  color: #17A2B8;
  border-radius: 8px;
  text-align: center;
  width: 70%;
  display: block;
}
.competitors-list-btn {
  background-color: #2ACD721A;
  color: #30AA4C;
  border-radius: 8px;
  text-align: center;
  width: 70%;
  display: block;
}
.list-btn-size{
  display: flex;
  width: 15%;
  gap: 10%;
}
.extra-info-container{
  border-radius: 5px;
  padding: 0.5vh 1vw;
  margin-top: 1vh;
}
.edit-user-icon{
  font-size: 1.7em;
  color: #606060;
  cursor: pointer;
}
.page-table-desc-column {
  padding: 0 0.7em;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.actions-width {
  width: 12%;
}
.id-width {
  width: 5%;
}
.group-width {
  width: 13%;
}
.corporate-width {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1vw;
}
.cnpj-width {
  width: 0%;
}
.phone-width {
  width: 0%;
}
.resale-width {
  width: 15%;
}
.area-width {
  width: 12%;
}
.state-width {
  width: 0%;
}
.responsible-width {
  width: 16%;
}
.status-width {
  width: 15%;
}
.answers-icon {
  font-size: 1.5em;
  color: #46AD5D;
  cursor: pointer;
  margin-right: 5px;
}
.monitor-icon {
  font-size: 1.5em;
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 5px;
}
.invite-icon {
  font-size: 1.5em;
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 5px;
}
.table-select-width {
  width: 15vw !important;
}
.old-show{
  background-color: rgb(248, 227, 227);
  padding-right: 1vw;
  border-radius: 8px;
}
.edit-table-icon {
  margin-right: 1.5em;
  cursor: pointer;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.page-table-header-mobile{
  display: none;
}
.attendances-page__infos-wrapper {
  display: flex;
  justify-content: space-between;
}
.mobile-alert-size{
  text-align: center;
}
.attendances-page__started-request {
  color: #404040;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.attendances-page__objective-request {
  color: rgb(46, 46, 248);
  background-color: lightblue;
  border-color: rgb(46, 46, 248);
  border-style: solid;
  border-width: 0;
  font-size: 1em;
  padding: 1vh 0.5vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__implanted-request {
  color: #0677C9;
  background-color: #17A2B81A;
  background-color: lightblue;
  border-color: #0677C9;
  border-style: solid;
  border-width: 0;
  font-size: 1em;
  padding: 1vh 0.5vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__waiting-first-request {
  color: var(--primary-color);
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__on-watch-request {
  color: #187C3B;
  font-size: 1em;
  font-weight: bold;
}
.extra-info-table-row{
  display: flex;
  justify-content: space-between;
}
.attendances-page__on-alert-request {
  color: #404040;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__suspended-request {
  color: #CD2A2A;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-finished-request {
  color: blue;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__total-request {
  color: rgb(197, 129, 2);
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 5px;
}
.pop-over-strong{
  color: var(--primary-color);
  font-weight: bold;
}
.total-requests-container {
  display: flex;
  justify-content: center;
  color: #878787;
  font-size: 1.2em;
}
.mobile-alert-size{
  cursor: pointer;
}
.attach-file-icon {
  font-size: 1.5em;
  color: #404040;
  cursor: pointer;
}
.expand-icon{
  font-size: 2em;
  color: #404040;
  cursor: pointer;
}
.expand-header{
  margin: 1vw 5vw;
  padding: 0.25em 0;
}
.expand-row{
  margin: 0 5vw;
}

/deep/
.status-select .multiselect__tags {
  padding: 16px 25px 32px 10px !important;
}
@media only screen and (max-width: 1050px) {
  .page-container {
    font-size: 1.25vw;
  }
}
@media only screen and (max-width: 900px) {
  .status-width {
    width: 24%;
  }
}
@media only screen and (max-width: 800px) {
  .page-container {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 630px){
  .desktop{display: none;}
  .attendances-page__clear-filter-container{
    margin-top: 2vh;
  }
  .page-container {
    font-size: 2.5vw;
    margin-right: 3vw;
  }
  .page-table-header{
    display: none;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .actions-width {
    width: 100%;
  }
  .id-width {
    width: 100%;
  }
  .group-width {
    width: 100%;
  }
  .corporate-width {
    flex: unset;
    width: 100%;
  }
  .cnpj-width {
    width: 100%;
  }
  .phone-width {
    width: 100%;
  }
  .resale-width {
    width: 100%;
  }
  .state-width {
    width: 100%;
  }
  .responsible-width {
    width: 100%;
  }
  .status-width {
    width: 100%;
  }
  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .page-table-input-select{
    width: fit-content;
  }
  .change-status{
    width: fit-content;
  }
  .page-table-header-mobile{
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
    display: flex;
  }
  .attendances-page__actions-container{
    flex-wrap: wrap;
  }
  .attendances-page__actions-inputs{
    width: 100%;
    margin-bottom: 2vh;
  }
  .excel-button{
    margin-left: 0;
    margin-right: auto !important;
  }
  .attendances-page__actions-wrapper{
    width: 100%;
  }
  .attendances-page__infos-wrapper{
    flex-wrap: wrap;
  }
  .mobile-alert-size{
    width: 45%;
    margin-bottom: 1vh;
    text-align: center;
  }
}
@media only screen and (max-width: 700px) {
  .page-container {
    font-size: 3vw;
  }
  .report-card-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh;
  }
  .report-card{
    width: 100%;
  }
  .report-card-graphic-container{
    grid-template-columns: 1fr;
  }
  .dashboard-table-container{
    width: 100%;
  }
  .attendances-page__infos-wrapper{
    grid-template-columns:  1fr;
  }
  .mobile-alert-size{
    width: 100%;
    justify-content: center !important;
  }
  .filter-button{
    width: 50%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 380px) {
  .page-container {
    font-size: 3.5vw;
  }
}
</style>
