<template>
    <div class="dashboard-table-container">
        <div class="dashboard-table-title">{{ title }}</div>
        <div class="body-container">
            <div class="dashboard-table-content-piechart">
                <vc-donut v-if="total" :sections="list" :total="total" :size="12" unit="vh"></vc-donut>
            </div>
            <div class="dashboard-table-list">
                <div v-for="card in list" @click="handleClick(card)" class="dashboard-table-row">
                    <div style="display: flex; align-items: center; flex: 1;">
                        <span v-if="card.id == selectedId" class="material-icons-outlined check-icon" style="color: gray;">check_box</span>
                        <span v-else class="material-icons-outlined check-icon">check_box_outline_blank</span>
                        <div class="dashboard-point" :style="'background-color: '+card.color"></div>
                        <div style="margin-left: 0.5vw; text-transform: capitalize;">{{ card.text && card.text.toLowerCase() }}</div>
                    </div>
                    <div class="dashboard-table-row-right2">
                        {{ card.value }}
                        ({{(card.value /total  * 100).toFixed(2)}}%)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportCard',
    props:['title', 'list', 'setValue', 'selectedId'],
    data(){
        return {
            total: undefined
        }
    },
    watch:{
        list(){
            this.total = this.list.reduce((t,v)=>t + v.value,0)
        }
    },
    methods:{
        handleClick(card){
            if (card.id == this.selectedId) this.setValue({id:null})
            else this.setValue(card)
        }
    },  
    beforeMount(){
        this.total = this.list.reduce((t,v)=>t + v.value,0)
    }
}
</script>

<style lang="scss" scoped>
.dashboard-table-container {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1vh 1vw;
    width: 52vw;
}

.dashboard-table-title {
    font-weight: 600;
    font-size: 1.4em;
    color: #505050;
}

.dashboard-table-content-piechart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.dashboard-table-list {
    flex: 1;
    overflow-y: auto;
    height: 30vh;
}

.dashboard-table-list::-webkit-scrollbar {
    height: 1vh;
    background: transparent;
    width: 1vw;
}

.dashboard-table-list::-webkit-scrollbar-thumb:vertical {
    background: lightgray;
    border-radius: 10px;
}

.dashboard-table-row {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
    margin-right: 0.5vw;
    font-size: 1.3em;
    cursor: pointer;
}
.dashboard-point {
    border-radius: 50%;
    height: 1vh;
    width: 1vh;
}

.dashboard-table-row-right {
    margin-left: 2vw;
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
}

.body-container {
    display: flex;
    justify-content: space-between;
}

.check-icon{
    color: lightgray;
    margin-right: 3%;
}
</style>